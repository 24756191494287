

import axios from 'axios';
import {PROD_FIREBASE_URL, DEV_FIREBASE_URL} from './constants';



//#region server
// export function addFirestoreServerRequest(params) {
//   return axios.post(`${FIREBASE_URL}/servers-servers`, params);
// }

// export function checkServerReachabilityRequest() {
//   return axios.get(`${FIREBASE_URL}/servers-servers/check`);
// }

// export function checkServerExistenceRequest(serverId) {
//   return axios.get(`${FIREBASE_URL}/${serverId}/check`);
// }
// //#endregion

// //#region user
// export function addFirestoreUserRequest(serverId, recipient, token) {
//   return axios.post(`${FIREBASE_URL}/v1/users/${serverId}`, {
//     recipient: recipient
//   }, {headers: {basic: token}});
// }

// export function updateFirestoreUserRequest(serverId, previousRecipient, nextRecipient, token) {
//   return axios.post(`${FIREBASE_URL}/v1/users/${serverId}/update`, {
//     previous_recipient: previousRecipient,
//     next_recipient: nextRecipient
//   }, {headers: {basic: token}});
// }

// export function deleteFirestoreUserRequest(serverId, recipient, token) {
//   return axios.delete(`${FIREBASE_URL}/v1/users/${serverId}/users/${recipient}`, {}, {headers: {basic: token}});
// }
//#endregion

export function sendNotificationRequest(serverId, title, body, actions, recipients, notificationType, token, phoneNumberToCall, locationGPS, locationDescription, isProd) {
  let firebaseUrl = "";
  if(isProd) {
    firebaseUrl = PROD_FIREBASE_URL;
  } else {
    firebaseUrl = DEV_FIREBASE_URL;
  }
  return axios.post(`${firebaseUrl}/v1/notifications/${serverId}`, {
    title, body, actions, recipients, type: 'alert', ringType: notificationType, phoneNumberToCall, locationGPS, locationDescription
  }, {headers: {basic: token}});
}
