<template>
  <div id="app">
    <b-container>
      <b-row class="m-3"><h1>myTamatNotif</h1></b-row>
      <!-- <ContactForm/> -->
      <router-view to="/"></router-view>
    </b-container>
    
  </div>
</template>

<script>
// import ContactForm from './components/ContactForm.vue';

export default {
  name: 'App',
  components: {
    // ContactForm
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
