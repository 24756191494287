


import Vue from "vue";
import VueRouter from "vue-router";
import ContactForm from './components/ContactForm';



Vue.use(VueRouter);

let router = new VueRouter({

  mode: "history",
  routes: [
    {
      name: "main",
      path: '/',
      component: ContactForm
    }

  ]
});

export default router;